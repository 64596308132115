

export async function getApi(param) {
    const response = await fetch(
        `https://cms.shantaequity.net/api/get-req-data/sections?type=slug&value=${param}&get_section=yes&image=yes&post=yes&file=yes&gallery=no`,
        {
            cache: "no-store",
        }
    );

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
}

export async function getNewsDetailsApi(slug) {
    const response = await fetch(
        `https://cms.shantaequity.net/api/get-req-data/blog-data?type=slug&value=${slug}`,
        {
            cache:"no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}

export async function getSearchApi(keyword) {
    const response = await fetch(
        `https://cms.shantaequity.net/api/get-req-data/new-search?keyword=${encodeURIComponent(keyword)}`,
        {
            cache:"no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}

export async function getFooterData() {
    const response = await fetch(
        `https://cms.shantaequity.net/api/get-req-data/settings-data`,
        {
            cache: "no-store",
        }
    );

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
}

