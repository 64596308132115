'use client'
import {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {hover} from '@/styles/globalStyleVars';
import reactHtmlParser from "react-html-parser";
import Link from "next/link";
import {getFooterData} from "@/api";

const MyComponent = ({ offset}) => {

    const [footerData, setFooterData] = useState(null); // State to hold the fetched data

    useEffect(() => {
        // Fetch footer data when component mounts
        getFooterData()
            .then(data => {
                setFooterData(data); // Update state with fetched data
            })
            .catch(error => {
                console.error('Error fetching footer data:', error);
            });
    }, []);

    return (
        <Footer offset={offset} className={'footer'}>
            <Container>
                <Row >
                    <Col className={'footer__logo'}>
                        <img height={40} width={168} src="/images/static/footer-logo.svg" alt=""/>
                    </Col>
                </Row>
                <Row className={'footer__top'}>

                    <Col md={3} sm={6}>
                        <div className="span">
                            <p className={'footer-desc'}>{footerData?.data?.slogan}</p>
                        </div>
                    </Col>
                    <Col md={{offset:1,span:3}} sm={6}>
                        <div className="span">
                            <h4>Corporate Office</h4>
                            <a href={footerData?.data?.office_location_two} target={'_blank'} rel={'noreferrer'}>
                                {reactHtmlParser(footerData?.data?.office_location)}
                            </a>
                        </div>
                        {/*<div className="span">*/}
                        {/*    <h4>Registered Office</h4>*/}
                        {/*    <a href={'https://maps.app.goo.gl/HuXK9Coq3ShAyJBU6'} target={'_blank'} rel={'noreferrer'}>Shanta*/}
                        {/*        Western Tower (Level-10), 186 Bir Uttam Mir Shawkat Sarak, Tejgaon, Dhaka-1208</a>*/}
                        {/*</div>*/}
                        <div className="span calls">
                            <h4>Call</h4>
                            <a href={`tel:${footerData?.data?.office_phone}`}>{footerData?.data?.office_phone}</a>
							<br/>
							<a href={`tel:${footerData?.data?.office_phone_2}`}>{footerData?.data?.office_phone_2}</a>
                        </div>
                        <div className="span">
                            <h4>Email</h4>
                            <a href={`mailto:${footerData?.data?.contact_email}`}>{footerData?.data?.contact_email}</a>
                        </div>
                    </Col>

                    <Col md={{span: 2}} sm={6} className={'footer-menu-1'}>
                        <h4>Explore</h4>
                        <ul className={'menu'}>
                            <li><Link prefetch={true} href={'/'}>
                                Home
                            </Link></li>
                            <li><Link prefetch={true} href={'/about-us'}>
                                About Us
                            </Link></li>
                            <li><Link prefetch={true} href={'/career'}>
                                Career
                            </Link></li>
                            <li><Link prefetch={true} href={'/form-download'}>
                                Forms & Downloads
                            </Link></li>
                            <li><Link prefetch={true} href={'/contact'}>
                                Contact Us
                            </Link></li>
                            <li><Link prefetch={true} href={'/privacy-policy'}>
                                Privacy Policy
                            </Link></li>
                        </ul>
                    </Col>
                    <Col md={2} sm={6} className={'footer-menu-2'}>
                        <h4>Services</h4>
                        <ul className={'menu'}>
                            <li><Link prefetch={true} href={'/services/investment-banking'}>
                                Investment Banking
                            </Link></li>
                            <li><Link prefetch={true} href={'/services/portfolio-management'}>
                                Portfolio Management
                            </Link></li>
                        </ul>
                    </Col>

                    <Col md={1} sm={6}>

                        <div className="span social-wrapper">
                            {/*<h4>Social</h4>*/}
                            <ul className="social">
                                {
                                    footerData?.data?.facebook &&
                                    <li><a target={"_blank"} href={footerData?.data?.facebook}>
                                        <img width={6} height={11.5} src="/images/static/fb.svg" alt=""/>
                                    </a></li>
                                }
                                {
                                    footerData?.data?.linkedin &&
                                    <li><a target={"_blank"}
                                           href={footerData?.data?.linkedin}>
                                        <img height={11} width={11} src="/images/static/linkedin.svg" alt=""/>
                                    </a></li>
                                }
                                {
                                    footerData?.data?.youtube &&
                                    <li><a target={"_blank"} href={footerData?.data?.youtube}>
                                        <img width={10} height={8} src="/images/static/youtube.svg" alt=""/>
                                    </a></li>
                                }
                                {
                                    footerData?.data?.instagram &&
                                    <li><a target={"_blank"} href={footerData?.data?.instagram}>
                                        <img height={12} width={12} src="/images/static/insta.svg" alt=""/>

                                    </a></li>
                                }


                            </ul>
                        </div>

                    </Col>
                </Row>

                <Row className={'footer__bottom'}>
                    <Col md={3} sm={6}>
                        <p>© {new Date().getFullYear()} Shanta Equity Ltd. All Rights Reserved.</p>

                    </Col>

                    <Col md={{span: 3, offset: 6}} className={'dc'}>
                        <p>Designed & Developed by <a href={'https://dcastalia.com/'} target={'_blank'}>Dcastalia</a>
                        </p>
                    </Col>
                </Row>
            </Container>
            <img className={'footer-svg'} src="/images/static/footer-svg.svg" alt=""/>
        </Footer>
    );
};

const Footer = styled.section`
    background-color: #3C3C3B;
    border-top: 1px solid rgba(151,140,133,0.5);
    padding-top: 60px;
    position: relative;
    .footer-svg{
        position: absolute;
        right: 0;
        bottom: 0;
        height: 90%;
    }
    .footer__top {
        padding-bottom: 75px;
        margin-top: 40px;
        border-bottom: 1px solid rgba(198, 198, 198, 0.50);
    }

    //img {
    //    margin-bottom: 40px;
    //}

    h4 {
        color: #978C21;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    a{
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.75);
        position: relative;
        z-index: 5;
    }
    p{
        font-size: 12px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.75);
    }

    .span {
        margin-bottom: 20px;
    }

    .menu {
        li {
            margin-bottom: 10px;
        }
    }
    
    .social-wrapper{
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-end;
    }

    .social {
        display: flex;
        flex-wrap: wrap;    
        flex-direction: column;

        li {
            //&:not(:nth-last-child(1)) {
            margin-right: 12px;
            //}

            a {
                height: 25px;
                width: 25px;
                border-radius: 50%;
                background-color: #FFF;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                margin-bottom: 10px;

                img {
                    margin: 0;
                    position: relative;
                    z-index: 2;
                    filter: brightness(0);
                    transition: .5s ease;
                }

                &:before {
                    height: 0%;
                    width: 0%;
                    border-radius: 50%;
                    position: absolute;
                    content: '';
                    background-color: ${hover};
                    margin: auto;
                    inset: 0;
                    transition: .5s ease;
                }

                &:hover {
                    &:before {
                        height: 100%;
                        width: 100%;
                    }
                    img{
                        filter: brightness(1);
                    }
                }
            }

        }
    }

    .footer__bottom {
        padding-bottom: 40px;
        padding-top: 30px;

        h4 {
            font-size: 12px;
            line-height: 20px;
        }
        p{
            font-size: 12px;
            line-height: 16px;
            color: #FFF;
        }

        a {
            font-size: 12px;
            line-height: 16px;
            color: #FFF;

            img {
                margin: 0 0 0 13px;
            }
        }
    }
    
    .dc{
        display: flex;
        justify-content: flex-end;
        a{
            position: relative;
            z-index: 9999999;
        }
    }

    @media (max-width: 767px) {
        .footer__logo {
            img{
                margin-bottom: 0;
            }
            h4 {
                margin-bottom: 30px;
            }
        }

        .span h4 {
            margin-bottom: 10px;
        }

        .calls {
            margin-top: 30px;
        }

        .footer__top {
            padding-bottom: 40px;
        }

        .footer__bottom {
            a {
                margin-top: 20px;
            }
        }
        
        .footer-menu-1{
            width: 45%;
        }
        .footer-menu-2{
            width: 50%;
        }
        .social-wrapper{
            align-items: flex-start;
            ul{
                flex-direction: row;
                margin-top: 30px;
            }
        }
        .dc{
            justify-content: flex-start;
        }
    }
    
    .footer-desc{
        font-size: 14px;
        line-height: 20px;
    }

`;


export default MyComponent;
